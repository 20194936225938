/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { isIOS, isAndroid } from 'react-device-detect'
import { useEventListener } from 'vspry-hooks'
import { ConfirmSwal, InfoSwal } from 'vspry-style-components'

import useBrowserStorage from 'hooks/browserStorage'
import { useState } from 'react'
import { translateContextless, useLocale } from 'context/localeContext'

const askFrequency = 1000 * 60 * 60 * 24 * 30

const deviceSpecificInstall = async (prompt: () => void) => {
    if (isIOS)
        InfoSwal.fire({
            title: await translateContextless('swal.installHelp.title'),
            text: await translateContextless('swal.installHelp.mobile.safari'),
        })
    else if (isAndroid && window.configuration['PLAY_STORE_APP_ID']) {
        window.location.href = `http://play.google.com/store/apps/details?id=${window.configuration['PLAY_STORE_APP_ID']}`
    } else prompt()
}

export default function AppInstaller() {
    const [lastAsked, setLastAsked] = useBrowserStorage('pwa-install', 0)
    const [prompt, setPrompt] = useState<any | null>(null)
    const { translate } = useLocale()

    const handleInstallPrompt = async (e: any) => {
        setPrompt(e)
        if (lastAsked > Date.now() - askFrequency || window.matchMedia('(display-mode: standalone)').matches) return
        e.preventDefault()
        const result = await ConfirmSwal.fire({
            title: translate('swal.installHelp.title'),
            text: translate('swal.installHelp.text'),
        })
        if (result.value && 'prompt' in e && e.prompt && typeof e.prompt === 'function') deviceSpecificInstall(e.prompt)
        setLastAsked(Date.now())
    }

    useEventListener('beforeinstallprompt', handleInstallPrompt)
    useEventListener('refireinstallprompt', () =>
        deviceSpecificInstall(() =>
            prompt
                ? prompt.prompt()
                : InfoSwal.fire({
                      title: translate('swal.installHelp.title'),
                      text: translate('swal.installHelp.mobile.chrome'),
                  })
        )
    )

    return null
}
